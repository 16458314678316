<template>
<v-app>
    <div>
        <v-form
                ref="form"
                @submit="changePassword"
                v-model="valid"
                :lazy-validation="lazy"
                class="pa-3"
        >
            <v-text-field
                v-model="data.current_password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[v => !!v || 'Password is required',v => v.length >= 6 || 'Min 6 characters']"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Current Password"
                dense
                outlined
                @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
                v-model="data.password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[v => !!v || 'Password is required',v => v.length >= 6 || 'Min 6 characters']"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="New Password"
                dense
                outlined
                @click:append="show2 = !show2"
            ></v-text-field>
            <v-text-field
                v-model="data.password_confirmation"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="conPassRules"
                :type="show2 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirm New Password"
                outlined dense
                @click:append="show3 = !show3"
            ></v-text-field>
            <v-btn
                small
                block
                :disabled="!valid"
                style="color:#fff"
                color="deep-orange darken-2"
                @click="changePassword()"
                :loading="loading"
            >
                Update password
            </v-btn>
        </v-form>
        <p class="ml-3">
                <router-link :to="{name: 'profile'}"> 
                    <span style="color:#e54c2a">Back To Profile</span> 
                </router-link>
            </p>
        <notifications group="update" position="top middle" />
        <!-- snackbar -->
            <v-snackbar v-model="snackbar">
                {{ text }}
                <v-btn color="pink" text  @click="snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        <!-- snackbar -->
    </div>
</v-app>
</template>

<script>
export default {
    name: 'change-password',
    data () {
        return {
            data: {},
                show1: false,
                show2: false,
                show3: false,
                loading: false,
                lazy: false,
                text: '',
                valid: true,
                snackbar: false,
                conPassRules: [
                    v => !!v || 'Name is required',
                    this.passwordConfirmationRule() || 'Password must match',
                ],
        }
    }, //end of data
    computed: {
        passwordConfirmationRule() {
            return () => (this.data.password === this.data.password_confirmation)
        },
    },

    methods: {
        async changePassword(){
            this.loading = true
            let message = await this.$store.dispatch("user/ACT_CHANGE_PASS", this.data)
            //console.log(message, "message")
            if(message.success == true){
                this.loading = false
                this.$refs.form.reset()
                this.$refs.form.resetValidation()
            this.$notify({
                group: 'update',
                type: 'success',
                title: 'Success',
                text: 'Information  updated successfully',
            });
            }else{
                this.loading = false
                this.snackbar = true
                this.text = message.message
                
            }
            
        },
    }, //end of methods
}
</script>

<style scoped>
    .v-application{
        height: 300px !important;
    }
</style>
